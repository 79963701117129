import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d7b5526"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.totalCount && _ctx.totalCount )
      ? (_openBlock(), _createBlock(_component_Pagination, {
          key: 0,
          size: "large",
          current: _ctx.currentPage,
          pageSize: _ctx.pageSize,
          total: _ctx.totalCount,
          pageSizeOptions: _ctx.pageSizeOptions,
          onChange: _ctx.changeCurrentPage,
          onShowSizeChange: _ctx.changePageSize
        }, {
          itemRender: _withCtx(({ type, originalElement }) => [
            (type === 'prev')
              ? (_openBlock(), _createElementBlock("a", _hoisted_2, "上一页"))
              : (type === 'next')
                ? (_openBlock(), _createElementBlock("a", _hoisted_3, "下一页"))
                : (_openBlock(), _createBlock(_resolveDynamicComponent(originalElement), { key: 2 }))
          ]),
          _: 1
        }, 8, ["current", "pageSize", "total", "pageSizeOptions", "onChange", "onShowSizeChange"]))
      : _createCommentVNode("", true)
  ]))
}