

import { defineComponent, PropType } from 'vue'

import Pagination from 'ant-design-vue/lib/pagination'
import 'ant-design-vue/lib/pagination/style/css'

export default defineComponent ({
  name: 'MyPagination',

  components: {
    Pagination
  },

  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    totalCount: {
      type: Number,
      default: 0
    },
    pageSizeOptions: {
      type: Array as PropType<string[]>,
      default: () => ([
        '10', '20', '30', '50'
      ])
    },
  },

  emits: ['onChangePagination'],

  setup (_, { emit }) {

    // 页码改变
    const changeCurrentPage = (page: number, pageSize: number) => {
      console.log('页码改变', page, pageSize)
      emitChangePagination(page, pageSize)
    }

    // 每页显示条数改变, 只要改变条数, 都查第一页
    const changePageSize = (page: number, pageSize: number) => {
      console.log('每页显示条数改变', page, pageSize)
      emitChangePagination(1, pageSize)
    }

    // emit - 改变分页数据
    const emitChangePagination = (currentPage: number, pageSize: number) => {
      emit('onChangePagination', {
        currentPage,
        pageSize
      })
    }

    return {
      changeCurrentPage,
      changePageSize
    }
  }

})

